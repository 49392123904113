<template>
    <div class="main_div">
      <div class="top_seat"></div>
      <div class="slider_container">
        <el-carousel :height="sliderStyle.height" indicator-position="none">
          <el-carousel-item v-for="(item, index) in sliders" :key="index">
            <div class="slider-wrapper">
              <img :src="baseUrl + item.imageUrl" class="slider_img" @click="turnToTarget(item)">
              <div class="day-counter">
                <span class="day-number">{{ getDayCount }}</span>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="top_seat"></div>
      <div class="type_div clearfix">
        <!-- 补充序号 -->
        <div class="type_piece serial-number-tp">
          <div class="type_piece_line serial-number-tpl">
            <div class="type_item_title">热门产品</div>
            <el-menu
                default-active="2"
                class="el-menu-vertical-demo"
                active-text-color="#7dbd26"
                unique-opened
            >
              <el-submenu
                  v-for="(childItem, childIndex) in serialNumberList"
                  :key="childIndex"
                  :index="childItem.index"
              >
                <template slot="title">
                  <div class="serial-number-item serial-number-item-center"><span class="menu-item-span">{{childIndex + 1}}</span></div>
                </template>
              </el-submenu>
            </el-menu>
          </div>
        </div>
        <!-- 补充序号 -->
        <div v-for="(lineItem, index) in [menuList.slice(0, 1), menuList.slice(1, 2), menuList.slice(2, 3), menuList.slice(3)]" :key="index" class="type_piece">
          <div v-for="(item, lineIndex) in lineItem" :key="lineIndex" class="type_piece_line">
              <div class="type_item_title">{{item.name}}</div>
            <el-menu
              default-active="2"
              class="el-menu-vertical-demo"
              active-text-color="#7dbd26"
              unique-opened
              >
              <el-submenu
                v-for="(childItem, childIndex) in item.categroyList"
                :key="childIndex"
                :index="childItem.index"
              >
                <template slot="title">
                  <div class="serial-number-item" @click="handleMenuItemClick(childItem)"><span class="menu-item-span">{{childItem.name}}</span><span v-if="childItem.lable" class="menu-item-span-label">{{ childItem.lable}}</span></div>
                </template>
                <!-- <el-menu-item
                  class="el-menu--inline-child"
                  v-for="(childSubItem, childSubIndex) in childItem.categroyList"
                  :key="childSubIndex"
                  :index="childSubItem.index"
                  @click="handleMenuItemClick(childSubItem)"
                >{{childSubItem.name}}</el-menu-item> -->
              </el-submenu>
            </el-menu>
          </div>
        </div>
      </div>
      <div class="clear_both"></div>
      <div class="content-block">
        <div v-if="industryShowRoomList.length" class="content-block-item">
          <div class="title_block_div">
            <div class="title_block">
              <img src="../assets/other/title.png">
              <div>行业展厅</div>
            </div>
          </div>
          <div v-for="(lineItem) in (Math.ceil(industryShowRoomList.length / 4) > 2 && industryShowRoomMore ? Math.ceil(industryShowRoomList.length / 4): 2)" :key="lineItem" class="type_img_div clearfix">
            <div v-for="(item, index) in industryShowRoomList.slice((lineItem - 1) * 4, lineItem * 4)" :key="index" @click="toCompany(item, 'industry')" class="type_img_block">
              <div class="type_img_block_content">
                <img :class="['logo-img', item.brandImageUrl ? '' : 'hide']" :src="baseUrl + item.brandImageUrl" style={} >
                <img :src="baseUrl + item.imageUrl">
              </div>
              <div class="type_img_block_title">
                <span>{{item.name}}</span>
                <img class="active_bg" src="../assets/other/more.png">
                <img class="default_bg" src="../assets/other/more2.png">
              </div>
            </div>
          </div>
          <div class="expand_more" v-if="industryShowRoomList.length > 8" @click="industryShowRoomMore = !industryShowRoomMore">{{industryShowRoomMore ? '关闭更多' : '展开更多'}}</div>
        </div>
        <!-- <div v-if="functionShowRoomList.length" class="content-block-item">
          <div class="title_block_div">
            <div class="title_block">
              <img src="../assets/other/title.png">
              <div>功能展厅</div>
            </div>
          </div>
           <div v-for="(lineItem) in (Math.ceil(functionShowRoomList.length / 4) > 2 && functionShowRoomMore ? Math.ceil(functionShowRoomList.length / 4): 2)" :key="lineItem" class="type_img_div clearfix">



            <div v-for="(item, index) in functionShowRoomList.slice((lineItem - 1) * 4, lineItem * 4)" :key="index"  @click="toPdfDetail(item, 'functionId')" class="type_img_block">
              <div class="type_img_block_content">
                <img :class="['logo-img', item.brandImageUrl ? '' : 'hide']" :src="baseUrl + item.brandImageUrl" style={} >
                <img :src="baseUrl + item.imageUrl">
              </div>
              <div class="type_img_block_title">
                <span>{{item.name}}</span>
                <img class="active_bg" src="../assets/other/more.png">
                <img class="default_bg" src="../assets/other/more2.png">
              </div>
            </div>

          </div>
          <div class="expand_more" v-if="functionShowRoomList.length > 8" @click="functionShowRoomMore = !functionShowRoomMore">{{functionShowRoomMore ? '关闭更多' : '展开更多'}}</div>
        </div> -->
        <div v-if="brandShowRoomList.length" class="content-block-item">
          <div class="title_block_div">
            <div class="title_block">
              <img src="../assets/other/title.png">
              <div>品牌展厅</div>
            </div>
          </div>
          <div v-for="(lineItem) in (Math.ceil(brandShowRoomList.length / 4) > 2 && brandShowRoomMore ? Math.ceil(brandShowRoomList.length / 4) : 2)" :key="lineItem" class="type_img_div clearfix">
            <div v-for="(item, index) in brandShowRoomList.slice((lineItem - 1) * 4, lineItem * 4)" :key="index"  @click="toCompanyDetails(item, 'brandId')" class="type_img_block">
              <div class="type_img_block_content">
                <img :class="['logo-img', item.brandImageUrl ? '' : 'hide']" :src="baseUrl + item.brandImageUrl" style={} >
                <img :src="baseUrl + item.imageUrl">
              </div>
              <div class="type_img_block_title">
                <span>{{item.name}}</span>
                <img class="active_bg" src="../assets/other/more.png">
                <img class="default_bg" src="../assets/other/more2.png">
              </div>
            </div>
          </div>
          <div class="expand_more" v-if="brandShowRoomList.length > 8" @click="brandShowRoomMore = !brandShowRoomMore">{{brandShowRoomMore ? '关闭更多' : '展开更多'}}</div>
        </div>
      </div>

      <!-- <div id="miniMenu">
        <div class="mini_menu">
          <img src="../assets/other/client.png"/>
          <p>客户端</p>
        </div>
        <div class="mini_menu">
          <img src="../assets/other/app.png"/>
          <p>APP</p>
        </div>
        <div class="mini_menu">
          <img src="../assets/other/official_account.png"/>
          <p>公众号</p>
        </div>
         <div class="mini_menu">
           <img src="../assets/other/customer_service.png"/>
           <p>在线客服</p>
         </div>
      </div> -->
    </div>
</template>
<script>
import { GetCategoryList, GetBannerList, GetRoomList, GetCategoryListOther  } from '../api/home/api'
import { baseUrl } from '../utils'
export default {
  name: 'Index',
  data() {
    return {
      baseUrl,
      sliders: [],
      visible: false,
      menuList: [],
      serialNumberList: [],
      sliderStyle: {},
      brandShowRoomList: [],
      functionShowRoomList: [],
      industryShowRoomList: [],
      industryShowRoomMore: false,
      functionShowRoomMore: false,
      brandShowRoomMore: false,
    }
  },
  methods: {
    toPdfDetail(item, fieldName) {
      let query =  {
        name: item.name,
        searching: 1
      };
      if(fieldName === 'industry') {
        query.level = 5;
        let fileds = Object.keys(item).filter(filedsItem => filedsItem.startsWith(fieldName));
        query.type = fileds[0];
        if(query.type === 'industrySecordCatId') {
          query.level = 6;
        }
        query.fieldName = 'industryType';
        query.fieldId = item[fileds[0]];
      } else if(fieldName === 'product') {
        query.level = 2;
        query.fieldName = 'productType';
        let fileds = Object.keys(item).filter(filedsItem => filedsItem.endsWith('CatId'));
        query.type = fileds[0];
        if(query.type === 'subCatId') {
          query.level = 2;
        } else if(query.type === 'thirdCatId') {
          query.level = 3;
        } else if(query.type === 'fourCatId') {
          query.level = 4;
        }
        query.fieldId = item[fileds[0]];
      } else if(fieldName === 'brandId') {
        query.level = 1;
        query.fieldName = 'brandType';
        query.type = 'brandId';
        query.fieldId = item.brandId;
      }  else if(fieldName === 'functionId') {
        query.level = 10;
        query.fieldName = 'functionType';
        query.type = 'functionId';
        query.fieldId = item.functionId;
      }
      this.$router.push({
        path: '/Pdf/PdfSearch',
        query
      });
    },
    turnToTarget(item) {
      this.$router.push(item.targetPath)
      // 跳转到展厅页面，暂时注释掉
      // this.$router.push({path: "/exhibitionHall"})
    },
    handleMenuItemClick(item) {
      this.$router.push({
        path: '/Pdf/PdfSearch',
        query: {
          level: 3,
          searching: 1,
          fieldId: item.id,
          fieldName: 'productType',
          type: 'thirdCatId',
          name: item.name
        }
      });
    },
    getRoomList() {
      GetRoomList().then(data => {
        let { brandShowRoomList, functionShowRoomList, industryShowRoomList } = data;
        this.brandShowRoomList = [...brandShowRoomList];
        this.functionShowRoomList = [...functionShowRoomList];
        this.industryShowRoomList = [...industryShowRoomList];
      })
    },
    getBanner() {
      GetBannerList().then(data => {
        if(data && data.length) {
          data.forEach(item => {
            item.targetPath = '/EntryPlatform';
          })
          this.sliders = [...data];
          // 获取图片高度
          if(data[0] && data[0].imageUrl) {
            this.loadImg(this.baseUrl + this.sliders[0].imageUrl, () => {
              this.$nextTick(() => {
                const ele = document.querySelector('.slider_img')
                ele && (this.sliderStyle = {...getComputedStyle(document.querySelector('.slider_img'), null)})
                if(!this.sliderStyle.height) {
                    this.sliderStyle.height = document.querySelector('.slider_img').offsetHeight + 'px';
                }
              })
            });
          }
        }
      })
    },
    getCategoryList() {
      GetCategoryListOther().then(data => {
        data.forEach((item, index) => {
          item.index = index + '';
          if(item.categroyList && item.categroyList.length) {
            item.categroyList.forEach((childItem, childIndex) => {
              childItem.index = index + '-' + childIndex;
              if(childItem.categroyList && item.categroyList.length) {
                childItem.categroyList.forEach((childSubItem, childSubIndex) => {
                  childSubItem.index = index + '-' + childIndex + '-' + childSubIndex;
                });
              }
            });
          }
        });
        this.menuList = [...data];
        this.serialNumberList = data[0].categroyList;
      })
    },
    loadImg(url, cb) {
      const oImg = new Image();
      oImg.onload = () => {
        cb && cb();
      };
      oImg.src = url;
    },
    toCompany(item, fieldName) {
      let query =  {
        name: item.name,
        searching: 1
      };
      if(fieldName === 'industry') {
        query.level = 5;
        let fileds = Object.keys(item).filter(filedsItem => filedsItem.startsWith(fieldName));
        query.type = fileds[0];
        if(query.type === 'industrySecordCatId') {
          query.level = 6;
        }
        query.fieldName = 'industryType';
        query.fieldId = item[fileds[0]];
      } else if(fieldName === 'product') {
        query.level = 2;
        query.fieldName = 'productType';
        let fileds = Object.keys(item).filter(filedsItem => filedsItem.endsWith('CatId'));
        query.type = fileds[0];
        if(query.type === 'subCatId') {
          query.level = 2;
        } else if(query.type === 'thirdCatId') {
          query.level = 3;
        } else if(query.type === 'fourCatId') {
          query.level = 4;
        }
        query.fieldId = item[fileds[0]];
      } else if(fieldName === 'brandId') {
        query.level = 1;
        query.fieldName = 'brandType';
        query.type = 'brandId';
        query.fieldId = item.brandId;
      }  else if(fieldName === 'functionId') {
        query.level = 10;
        query.fieldName = 'functionType';
        query.type = 'functionId';
        query.fieldId = item.functionId;
      }
      try {
        this.$router.push({
          path: '/Company/CompanyList',
          query
        });
        console.log('Navigation triggered');
      } catch (err) {
        console.error('Navigation error:', err);
        this.$message.error('页面跳转失败，请重试');
      }
    },
    toCompanyDetails(item, fieldName) {
      let query =  {
        companyId: item.companyId
      };
      this.$router.push({
          path: '/Company/CompanyDetails',
          query
        });
    }
  },
  mounted() {
    this.getBanner();
    this.getCategoryList();
    this.getRoomList();
  },
  computed: {
    getDayCount() {
      const startDate = new Date('2024-10-15');
      const today = new Date();
      const diffTime = Math.abs(today - startDate);
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      
      // 计算每月闭门日
      const months = Math.floor(diffDays / 30);
      diffDays -= months * 2;
      
      // 计算春节等公共假期
      const year = today.getFullYear();
      // 根据农历计算春节日期
        const springFestival = getLunarNewYear(year);
        function getLunarNewYear(year) {
          // 2024年春节是2月10日
          if (year === 2024) return new Date(2024, 1, 10);
          // 2025年春节是1月29日
          if (year === 2025) return new Date(2025, 0, 29);
          // 其他年份默认2月1日
          return new Date(year, 1, 1);
        }
      if (today >= springFestival) {
        diffDays -= 11;
      }
      
      return diffDays;
    }
  },
}
</script>

<style scoped>
/* .el-carousel__container, */
.slider_img{
  /* max-width:100%;
  max-height:100%; */
  width: 100%;
  /* height: 100%; */
  /* height: 130px; */
}
.top_seat {
  height: 10px;
  background-color: #e8e8e8;
}

.type_div {
  padding: 0 10px;
  display: flex;
  background-color: #e8e8e8;
  /* display: flex; */
}
.content-block {
  padding: 0 0 30px 0;
}

.content-block-item {
  padding-top: 30px;
  cursor: pointer;
}

.type_img_div {
  background-color: #e8e8e8;
  display: flex;
  padding: 10px 10px 0;
  width: 100%;
  box-sizing: border-box;
}

.main_div {
  background-color: #e8e8e8;
}
.el-menu-vertical-demo {
  font-family: PingFangSC-Regular, PingFang SC;
}

.type_piece {
  /* float: left; */
  padding: 0 10px 0 0;
  flex: 1;
  /* min-width: 454px; */
  /* max-height: 504px; */
  /* overflow-y: auto; */
  /* flex: 1; */
}
/* .type_piece_line{
  margin-bottom: 20px;
}
.type_piece_line:nth-last-child(1) {
  margin-bottom: 0;
} */
.type_piece:nth-last-child(1) {
  padding-right: 0;
}
/* .type_piece_line:hover .type_item_title {
  background-color: var(--main-color);
  color: #fff;
} */
.type_piece .el-menu-item {
  height: 46px!important;
  line-height: 46px!important;
  text-overflow: ellipsis;
  overflow: hidden;
}
.type_piece_line:hover .el-menu--inline-child.el-menu-item.is-active {
  background-color: unset !important;
  color: var(--main-color) !important;
}
.type_piece_line:hover .el-menu-item:hover {
  background-color: var(--main-color) !important;
  color: #fff;
}
.type_piece >>> .el-submenu__title {
  height: 66px;
  line-height: 66px;
  box-sizing: border-box;
  font-size: 18px;
}
.type_piece >>> .el-menu-item {
  font-size: 22px;
}
.type_piece_line >>> .el-submenu:hover .el-submenu__title {
  background-color: unset;
  color: var(--main-color);
}

.type_img_block {
  /* float: left; */
  /* flex: 1; */
  /* padding: 10px 10px 0px 10px; */
  padding: 0 24px;
  margin: 0 2% 10px 0;
  /* width: 424px; */
  height: 306px;
  background-color: #fff;
  border-bottom: 7px solid #c2c2c2;
  box-sizing: border-box;
  width: 23.5%;
}
.type_img_block:nth-last-child(1) {
  margin-right: 0;
}

.type_img_block_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.type_img_block_content img {
  width: 100%;
  height: 200px;
  border: 1px solid #dedede;
}
.type_img_block_content .logo-img {
  width: 108px;
  height: 40px;
  margin-left: auto;
  border: none;
}
.type_img_block_content .logo-img.hide {
  opacity: 0;
}

.type_img_block_title {
  /* display: flex; */
  /* margin: 10px 5px; */
  height: 64px;
  line-height: 64px;
  position: relative;
  font-family: PingFangSC-Regular, PingFang SC;
  text-align: center;
  font-size: 18px;
}
.type_img_block:hover {
  border-bottom: 7px solid var(--main-color);
}
.type_img_block:hover .default_bg {
  display: none;
}
.type_img_block:hover .active_bg {
  display: block;
}

.type_img_block_title div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 425px;
  font-size: 18px;
  color: #b1b1b1;
}

.type_img_block_title img{
  position: absolute;
  right: -14px;
  bottom: 6px;
  width: 32px;
  height: 20px;
  display: none;
}
.type_img_block_title .default_bg {
  display: block;
}

.type_item_title {
  height: 46px;
  line-height: 46px;
  background-color: var(--main-color);
  padding: 10px 12px 10px 28px;
  font-size: 18px;
  color: #fff;
  display: flex;
  align-items: center;
}

.type_item {
  padding: 10px 20px  10px 48px;
  display: flex;
  border-bottom: 1px solid #e9e9e9;
  justify-content: space-between;
  background-color: #fff;
  font-size: 19px;
  color: #7a7a7a;
  text-align: center;
  align-items: center;
}

.type_pop_content {
  background-color: #f3f3f3;
}

.type_pop_content span {
  padding: 5px 10px;
  font-size: 20px;
  color: #7a7a7a;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.title_block {
  display: flex;
  border-bottom: 5px solid var(--main-color);
  width: 190px;
  /* margin-left: 80px; */
  align-items: center;
}

.title_block img {
  width: 15px;
  height: 15px;
}

.title_block div {
  font-size: 22px;
  color: var(--main-color);
  margin-left: 24px;
  font-weight: bold;
}
.title_block_div {
  /* padding-top: 30px; */
  display: flex;
  justify-content: space-between;
  height: 48px;
  line-height: 48px;
  padding: 0 10px;;
}

.more {
  color: #e8e8e8;
  background-color: var(--main-color);
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  /* margin-right: 60px; */
  cursor: pointer;
  /* font-family: PingFangSC-Regular, PingFang SC; */
  font-family: Microsoft YaHei;
}

#miniMenu{
  position: fixed;
  bottom: 50px;
  right: 30px;
  cursor: pointer;
  background-color: #f3f3f3;
  z-index: 999;
  width: 100px;
}

.mini_menu {
  color: #cbcbcb;
  font-size: 15px;
  border-bottom: 2px solid #cbcbcb;
  width: 80px;
  margin: 25px auto;
}

.mini_menu img {
  margin: 0 auto;
  display: block;
}

.mini_menu p {
  text-align: center;
}

#miniMenu div:last-child {
  border-bottom: none;
}

#miniMenu div:first-child img {
  width:52px;
  height:42px;
}
#miniMenu div:nth-child(2) img {
  width:24px;
  height:48px;
}
#miniMenu div:nth-child(3) img {
  width:54px;
  height:36px;
}
#miniMenu div:last-child img {
  width:34px;
  height:54px;
}
.type_piece >>> .el-submenu__icon-arrow.el-icon-arrow-down {
  display: none;
}

.day-counter {
  position: absolute;
  top: 185px;
  left: 905px;
  transform: translate(-50%, -50%);
  /*background: rgba(255, 255, 255, 0.9);*/
  padding: 10px 15px;
  border-radius: 4px;
  text-align: center;
}

.day-number {
    font-size: 30px;
    font-weight: bold;
    color: #394556;
    font-family: Microsoft YaHei;
}

.day-text {
  font-size: 14px;
  color: #666;
  margin-left: 2px;
}
</style>
<style>
/*最外层div,修改背景色，边框*/
.el-popover.monitor-yt-popover {
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}
/*修改title颜色*/
.monitor-yt-popover .el-popover__title {
  color: white;
}
/*修改下面的小三角，属性名根据组件的placement位置做相应修改*/
.monitor-yt-popover .popper__arrow::after {
  border-bottom-color: #f3f3f3 !important;
}

.serial-number-item {
    display: flex;
}
.serial-number-item-div {
    border-right: 1px solid black;
    width: 40px;
    padding-right: 5px;
    margin-right: 10px;
    margin-top: -1px;
    text-align: center;
}
.serial-number-item-title {
    /*border-right: 1px solid #ffffff;*/
    width: 40px;
    margin-right: 10px;
}

.serial-number-tp {
  flex: inherit !important;
}

.serial-number-tpl {
  width: 118px;
}

.serial-number-item-center {
  justify-content: center;
}

.expand_more {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  color: #7a7a7a;
  cursor: pointer;
  margin-top: 10px;
  box-sizing: border-box;
}

.menu-item-span-label {
  /*类似于热榜的标识，放到字段最后，超出部分点点点*/
  position: absolute;
  right: 0;
  top: 0;
  width: 70px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #ff0000;
  color: #ffffff;
  border-radius: 20%;
  font-size: 12px;
  font-weight: bold;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transform: translate(-28%, 25%);

}

.el-submenu__title {
  height: 46px !important;
  line-height: 46px !important;
}
</style>
