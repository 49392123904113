<template>
  <div class="product_discussion_div">
    <div class="slider_container">
      <el-carousel :height="sliderStyle.height" indicator-position="none">
        <el-carousel-item v-for="(item, index) in sliders" :key="index">
          <div class="slider-wrapper">
            <img :src="baseUrl + item.imageUrl" class="slider_img" @click="turnToTarget(item)">
            <div class="day-counter">
              <span class="day-number">{{ getDayCount }}</span>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 列表页面 -->
    <div class="content" v-show="question == 1">
      <div class="content_left">
        <div class="product_discussion_menu">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link product_discussion_menu_left">
              {{ selectMenuName }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" :append-to-body="false">
              <el-dropdown-item :command="{name: '全部', id: 0}">全部</el-dropdown-item>
              <el-dropdown-item :command="item"  v-for="(item,index) in menus" :key="index">{{ item.name }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
<!--          <div class="product_discussion_menu_left">-->
<!--            <div :class="msgType === 0 ? 'select' : ''" @click="selectMenu(0)">全部</div>-->
<!--            <div v-for="(item,index) in menus" :key="index" :class="item.id === msgType ? 'select' : ''" @click="selectMenu(item.id)">{{ item.name }}</div>-->
<!--          </div>-->
<!--          <div class="product_discussion_menu_right">-->
<!--            <span>最新</span>-->
<!--            <span>最热</span>-->
<!--            <span>未解决</span>-->
<!--            <span>已解决</span>-->
<!--          </div>-->
        </div>
        <div class="product_discussion_content">
          <div v-if="!dataList || dataList.length < 1">
            <div class="no_data">
              <p>暂无数据</p>
            </div>
          </div>
          <div v-else class="product_discussion_content_item padding_none" @click="details(item)" v-for="(item,index) in dataList" :key="index">
            <div class="content_item_left">
              <div class="product_discussion_content_status default_color">
                <div>{{ item.msgTypeStr }}</div>
              </div>
            </div>
            <div>
              <div class="content_item_title">
                <div class="content_item_title_text">{{item.title}}</div>
                <template v-if="item.label">
                  <div class="label" v-for="(item2,index2) in item.label.split(',')" :key="index2">
                    <div>{{item2}}</div><div></div>
                  </div>
                </template>
              </div>
              <div class="content_item_bottom">
                <span><img :src="item.headImage">{{ item.userName }}</span>
                <span>{{ parseTime(item.createTime, "{y}-{m}-{d}") }}</span>
                <span><i class="el-icon-view"></i>{{ item.browseCount }}</span>
                <span><i class="el-icon-chat-line-square"></i>{{ item.commentCount }}</span>
              </div>
            </div>
          </div>
        </div>
        <paging v-if="total > pageSize" :total="total" @handleCurrentChange="handleCurrentChange"></paging>
      </div>
      <questions-right @openQuestions="openQuestions" :hot-issues-list="hotIssuesList" :problem-solving-ranking-list="problemSolvingRankingList"></questions-right>
    </div>
    <!-- 提问页面 -->
    <div class="questions2" v-show="question == 2">
      <div class="questions2_head"><i class="el-icon-edit-outline"></i>发布帖子</div>
      <div class="questions2_title">
        <input type="text" placeholder="填写标题" v-model="form.title">
      </div>
<!--      <div class="questions2_describe">-->
<!--        <span>添加图片<img src="../assets/other/sendPictures.png"></span>-->
<!--        <span>添加表情<img src="../assets/other/emoticon.png"></span>-->
<!--        <span>（添加图片不超过3张）</span>-->
<!--        <span>发帖请遵守法律法规</span>-->
<!--      </div>-->
      <div class="questions2_label">
        <span>填写标签（最多不超过3个）</span>
        <div class="questions2_label_content">
          <span v-for="(item,index) in labels" :key="index"> <p>{{item}}</p> <i class="el-icon-delete" @click="deleteLabel(item)"></i></span>
          <input type="text" placeholder="" v-model="labelValue" @keyup.enter="addLabel" @blur="addLabel()">
          <div @click="addLabel"><i class="el-icon-plus"></i></div>
        </div>
      </div>
      <div class="questions2_label">
        <el-radio-group v-model="form.msgType">
          <el-radio :label="item.id" v-for="(item,index) in menus" :key="index">{{item.name}}</el-radio>
        </el-radio-group>
      </div>
      <div class="questions2_textarea" v-if="(uuid && detailsData.title) || !uuid">
<!--        <textarea placeholder=""></textarea>-->
        <editor-bar @change="editorChange" :value="form.content"></editor-bar>
      </div>
      <div class="published" @click="published">发表</div>
    </div>
    <!-- 详情页面 -->
    <div class="questions3 content" v-show="question == 3">
      <div class="content_left">
        <div class="questions3_title" v-if="detailsData.title">[提问] {{ detailsData.title}}</div>
        <div class="questions3_top_illustrate">
          <span v-if="detailsData.createTime">{{ parseTime(detailsData.createTime, "{y}-{m}-{d}") }}</span>
          <span><i class="el-icon-view"></i>{{ detailsData.browseCount }}</span>
          <span><i class="el-icon-chat-line-square"></i>{{ detailsData.commentCount }}</span>
          <template v-if="detailsData && detailsData.label">
            <div :class="index2 == 0 ? 'label spacing' : 'label'" v-for="(item2,index2) in detailsData.label.split(',')" :key="index2">
              <div>{{item2}}</div><div></div>
            </div>
          </template>
        </div>

        <div class="questions3_discussion_content_item">
          <div class="questions3_content_item_left">
            <span><img class="imageHeader" :src="detailsData.headImage" v-if="detailsData.headImage"><img class="imageHeader" v-else src="../../assets/other/user.png">{{ detailsData.userName }}</span>
            <div class="landlord">楼主</div>
            <div class="center">
              <div class="questions3_discussion_content_status default_color">
                <div>{{detailsData.msgTypeStr}}</div>
              </div>
            </div>
          </div>
          <div>
            <div class="questions3_content_item_title">
              <div v-html="detailsData.content"></div>
<!--              <div class="questions3_bottom_illustrate">-->
<!--                <span>ip属地：上海</span>-->
<!--                <span>举报</span>-->
<!--                <span>来自Android客户端</span>-->
<!--                <span>1楼</span>-->
<!--                <span>2023-05-10</span>-->
<!--                <span>2023-05-10 20：53</span>-->
<!--                <span>回复</span>-->
<!--                <span>引用</span>-->
<!--              </div>-->
            </div>
          </div>
        </div>

        <div class="answer_number">
          {{ answerList.length }}个回答
        </div>
        <div class="answer_list" v-for="(item,index) in answerList" :key="index">
          <div class="answer_list_content" v-html="item.content"></div>
          <div class="answer_list_operate" :ref="'answer' + item.id">
            <div class="answer_list_name"><img class="imageHeader" :src="item.headImage" v-if="item.headImage"><img class="imageHeader" v-else src="../../assets/other/user.png"><span>{{item.userName}}</span></div>
            <div>{{ parseTime(item.createTime, "{y}-{m}-{d} {hh}:{mm}") }}</div>
            <div @click="openComments(item)">评论</div>
          </div>
          <div v-if="showInput == item.id">
            <el-input placeholder="请输入评论..." v-model="commentsForm.content">
              <el-button slot="append" @click="commentsSubmission">提交</el-button>
            </el-input>
          </div>
          <div class="comment_number" v-if="item.child.length > 0">{{ item.child.length }}条评论</div>
          <template v-if="item.child.length > 0">
            <div v-for="(item2,index2) in item.child" :key="index2">
              <div class="comment_name"><span><img class="imageHeader" :src="item2.headImage" v-if="item2.headImage"><img class="imageHeader" v-else src="../../assets/other/user.png"></span><span class="nick_name">{{ item2.userName }}</span> {{parseTime(item2.createTime, "{y}-{m}-{d} {hh}:{mm}")}}</div>
              <div class="comment_content">{{item2.content}}</div>
              <div class="comment_operate" @click="openComments(item2)">回复</div>
              <div v-if="showInput == item2.id">
                <el-input :placeholder="'回复 ' + item2.parentName" v-model="commentsForm.content">
                  <el-button slot="append" @click="commentsSubmission">提交</el-button>
                </el-input>
              </div>
              <div class="reply" v-for="(item3,index3) in item2.child" :key="index3">
                <div class="reply_name"><span>{{ item3.userName }}</span> 回复 <span>{{ item3.parentName }}</span>： {{parseTime(item3.createTime, "{y}-{m}-{d} {hh}:{mm}")}}</div>
                <div class="reply_content">{{ item3.content }}</div>
                <div class="reply_operate" @click="openComments(item3)">回复</div>
                <div v-if="showInput == item3.id">
                  <el-input placeholder="请输入回复..." v-model="commentsForm.content">
                    <el-button slot="append" @click="commentsSubmission">提交</el-button>
                  </el-input>
                </div>
              </div>
            </div>
          </template>
        </div>



        <div v-show="detailsData.title && !showLogin">
          <div class="questions2_describe center_align">
            <div class="questions2_head"><i class="el-icon-edit-outline"></i>我来回答</div>
            <!--          <span class="questions3_describe_span">添加图片<img src="../assets/other/sendPictures.png"></span>-->
            <!--          <span>添加表情<img src="../assets/other/emoticon.png"></span>-->
            <!--          <span>（添加图片不超过3张）</span>-->
            <!--          <span>发帖请遵守法律法规</span>-->
          </div>
          <div class="questions2_textarea">
            <!--          <textarea placeholder=""></textarea>-->
            <editor-bar @change="editorChange" :value="form.content"></editor-bar>
          </div>
          <div class="published" @click="answer">发表</div>
        </div>
      </div>
      <questions-right @openQuestions="openQuestions" :hot-issues-list="hotIssuesList" :problem-solving-ranking-list="problemSolvingRankingList"></questions-right>

    </div>
  </div>
</template>
<script>
import { baseUrl } from '../../utils';
import paging from '../public/paging.vue'
import { GetBannerList  } from '../../api/home/api'
import EditorBar from "@/components/Editor";
import {GetDict, List, Add, Update, getById, msgBrowse, noAuthMsgBrowse, msgList, addMsgList, hotIssues, problemSolvingRanking} from '../../api/productDiscussion/api.js';
import questionsRight from "./questionsRight.vue";
import Bus from "@/bus";
export default {
  name: 'ProductDiscussion',
  data() {
    return {
      baseUrl,
      sliders: [],
      sliderStyle: {},
      pageNum: 1,
      pageSize: 10,
      total: 100,
      question: 1,
      form: {
        content: '',
        title: '',
        msgType: ''
      },
      menus: [],
      msgType: 0,
      dataList: [],
      detailsData: {},
      labels: [],
      labelValue: '',
      uuid: '',
      editorContent: '',
      answerList: [],
      commentsForm: {},
      commentsData: {},
      showInput: '',
      hotIssuesList: [],
      problemSolvingRankingList:[],
      loading: '',
      isEdit: false,
      showLogin: false,
      selectMenuName: '全部'
    }
  },
  components: {
    EditorBar,
    paging,
    questionsRight
  },
  created() {
    let question = this.$route.query.question;
    if (question) {
      this.question = question;
    }
    let uuid = this.$route.query.uuid;
    if (uuid) {
      this.uuid = uuid;
    }
    // 编辑页面
    if (this.question == 2 && this.uuid) {
      this.openLoading();
      this.isEdit = true;
      this.getInfoById(uuid);
    }
    if (this.question == 3) {
      this.openLoading();
      this.addMsgBrowse(uuid);
      this.getInfoById(uuid);
      this.getMsgList(uuid);
    }
    this.getDiscussionMenu();
    this.selectList();
    this.rankingAll();
  },
  methods: {
    getBanner() {
      GetBannerList().then(data => {
        if(data && data.length) {
          data.forEach(item => {
            item.targetPath = '/EntryPlatform';
          })
          this.sliders = [...data];
          // 获取图片高度
          if(data[0] && data[0].imageUrl) {
            this.loadImg(this.baseUrl + this.sliders[0].imageUrl, () => {
              this.$nextTick(() => {
                const ele = document.querySelector('.slider_img')
                ele && (this.sliderStyle = {...getComputedStyle(document.querySelector('.slider_img'), null)})
              })
            });
          }
        }
      })
    },
    loadImg(url, cb) {
      const oImg = new Image();
      oImg.onload = () => {
        cb && cb();
      };
      oImg.src = url;
    },
    handleCurrentChange(value) {
      this.pageNum = value;
      this.selectList();
    },
    editorChange(val) {
      this.editorContent = val
    },
    // 发表
    published() {
      if (!this.form.title) {
        this.$message({
          message: '请填写标题',
          type: 'warning'
        });
        return;
      }
      if (this.labels) {
        this.form.label = this.labels.join(',')
      }
      this.form.content = this.editorContent;
      this.openLoading("数据上传中...");
      if (this.isEdit) {
        Update(this.form).then(res => {
          this.question = 1;
          this.editorContent = '';
          this.form = {
            content: '',
            title: '',
            msgType: ''
          };
          this.selectList();
          this.closeLoading();
        })
      } else {
        Add(this.form).then(res => {
          this.question = 1;
          this.editorContent = '';
          this.form = {
            content: '',
            title: '',
            msgType: ''
          };
          this.selectList();
          this.closeLoading();
        })
      }
    },
    // 获取菜单数据
    getDiscussionMenu () {
      GetDict({code: 'forum_type'}).then(res => {
        this.menus = res.list
        if (this.menus) {
          this.form.msgType = this.menus[0].id
        }
      });
    },
    // 选择菜单
    selectMenu(id) {
      this.msgType = id;
      this.selectList();
    },
    // 查询列表数据
    selectList() {
      List({msgType: this.msgType, pageNum: this.pageNum, pageSize: this.pageSize}).then(res => {
        this.total = res.total;
        this.dataList = res.list;
      })
    },
    // 打开详情
    details(data) {
      //this.question = 3
      //this.detailsData = data
      var route = this.$router.resolve({
        name: 'ProductDiscussion',
        query:{
          question:3,
          uuid: data.uuid
        }
      })
      window.open(route.href, '_blank')
    },
    // 完成标签
    addLabel() {
      if (this.labelValue != '') {
        if (!this.validateInput(this.labelValue)) {
          this.$message({
            message: '请输入汉字或字母数字长度不能超过10',
            type: 'warning'
          });
          return;
        }
        if (this.labels.length < 3) {
          this.labels.push(this.labelValue);
          this.labelValue = '';
        } else {
          this.$message({
            message: '最多添加3个标签',
            type: 'warning'
          });
        }
      }
    },
    // 打开提问
    openQuestions() {
      if (!this.isLogin()) {
        Bus.$emit('showLogin', true);
        return;
      }
      // this.question = 2
      this.labels = []
      this.labelValue = ''
      this.form = {
        content: '',
        title: '',
        msgType: ''
      }
      var route = this.$router.resolve({
        name: 'ProductDiscussion',
        query:{
          question:2
        }
      })
      window.open(route.href, '_blank')
    },
    // 删除标签
    deleteLabel(label) {
      this.labels = this.labels.filter(item=>item!==label)
    },
    isLogin() {
      return !!(window.localStorage && window.localStorage.getItem('token'))
    },
    // 获取详情数据
    getInfoById(uuid) {
      getById({uuid: uuid}).then(res => {
        this.detailsData = res;
        if (this.isEdit) {
          this.form = {
            content: res.content,
            title: res.title,
            msgType: res.msgType,
            id: res.id
          }
          this.labels = res.label ? res.label.split(',') : []
        }
        this.closeLoading();
      })
    },
    // 记录浏览量
    addMsgBrowse(uuid) {
      if(!this.isLogin()) {
        noAuthMsgBrowse({uuid: uuid}).then()
      } else {
        msgBrowse({uuid: uuid}).then()
      }
    },
    // 详情页回复列表
    getMsgList(uuid) {
      msgList({messageUUID: uuid}).then(res=> {
        // this.answerList = res;
        this.reassemblingData(res);
      })
    },
    reassemblingData(data) {
      this.answerList = [];
      if (!data) {
        return;
      }
      data.forEach(one=> {
        one.child.forEach(two=> {
          let list = [];
          two.child.forEach(three=> {
            this.getAnswerThreeAll(three,list);
          })
          two.child = list;
        })
        this.answerList.push(one);
      })
    },
    getAnswerThreeAll(data,list) {
      list.push(data)
      if (data.child && data.child.length > 0) {
        data.child.forEach(d => {
          this.getAnswerThreeAll(d,list);
        })
      }
    },
    // 回答回复
    answer() {
      if (!this.isLogin()) {
        Bus.$emit('showLogin', true);
        return;
      }
      this.form.content = this.editorContent;
      if (!this.form.content) {
        this.$message({
          message: '请填写评论内容',
          type: 'warning'
        });
        return;
      }
      this.form.messageUUID = this.uuid;
      this.form.parentId = 0;
      addMsgList(this.form).then(res => {
        this.form.content = '';
        this.editorContent = '';
        this.getMsgList(this.uuid);
      })
    },
    // 打开评论回复
    openComments(data, endId) {
      this.commentsData = data;
      this.commentsForm = {
        content: "",
        messageUUID: this.uuid,
        parentId: endId ? endId : data.id
      }
      this.showInput = data.id;
    },
    // 评论回复提交
    commentsSubmission() {
      if (!this.isLogin()) {
        Bus.$emit('showLogin', true);
        return;
      }
      if (!this.validateReply(this.commentsForm.content)) {
        this.$message({
          message: '请输入汉字或字母数字长度不能超过60',
          type: 'warning'
        });
        return;
      }
      this.showInput = '';
      addMsgList(this.commentsForm).then(res => {
        this.commentsForm = {};
        this.getMsgList(this.uuid);
      })
    },
    rankingAll() {
      hotIssues().then(res=> {
        this.hotIssuesList = res;
      })
      problemSolvingRanking().then(res=> {
        this.problemSolvingRankingList = res;
      })
    },
    validateInput(input) {
      var regex = /^[a-zA-Z0-9\u4e00-\u9fa5]{1,10}$/;
      return regex.test(input);
    },
    validateReply(input) {
      var regex = /^[a-zA-Z0-9\u4e00-\u9fa5]{1,60}$/;
      return regex.test(input);
    },
    openLoading(text) {
      this.loading = this.$loading({
        lock: true,
        text: text ? text : '资源加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    closeLoading() {
      this.loading.close();
    },
    handleCommand(command) {
      this.msgType = command.id;
      this.selectMenuName = command.name;
      this.selectList();
    },
    turnToTarget(item) {
      this.$router.push(item.targetPath)
      // 跳转到展厅页面，暂时注释掉
      // this.$router.push({path: "/exhibitionHall"})
    },
  },
  computed: {
      getDayCount() {
          const startDate = new Date('2024-10-15');
          const today = new Date();
          const diffTime = Math.abs(today - startDate);
          let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          // 计算每月闭门日
          const months = Math.floor(diffDays / 30);
          diffDays -= months * 2;

          // 计算春节等公共假期
          const year = today.getFullYear();
          // 根据农历计算春节日期
          const springFestival = getLunarNewYear(year);
          function getLunarNewYear(year) {
              // 2024年春节是2月10日
              if (year === 2024) return new Date(2024, 1, 10);
              // 2025年春节是1月29日
              if (year === 2025) return new Date(2025, 0, 29);
              // 其他年份默认2月1日
              return new Date(year, 1, 1);
          }
          if (today >= springFestival) {
              diffDays -= 11;
          }

          return diffDays;
      }
  },
  mounted() {
    this.getBanner();
    Bus.$on('showLogin', val => {
      this.showLogin = val;
    });
  }
}
</script>
<style scoped>
::v-deep .el-dropdown-menu {
    min-width: 220px;
    position: absolute !important;
    top: 35px !important;
    left: -5px !important;
}
.slider_container {
  padding: 10px 0;
}
.slider_img {
  width: 100%;
}
.product_discussion_div {
  min-height: 600px;
  background-color: #e8e8e8;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center_align {
  display: flex;
  align-items: center;
}

.product_discussion_img {
  width: 1958px;
  height: 252px;
  margin-top: 9px;
}

.content {
  display: flex;
}

.content_left {
  min-width: 1495px;
}



.product_discussion_menu {
  display: flex;
  /*overflow-x: auto;*/
  margin-left: 35px;
  margin-bottom: 5px;
}
.product_discussion_menu::-webkit-scrollbar {
  width : 10px;
  height: 10px;
}
.product_discussion_menu::-webkit-scrollbar-thumb {
  border-radius   : 10px;
  background-color: var(--main-color);
  background-image: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent
  );
}
.product_discussion_menu::-webkit-scrollbar-track {
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  background   : #ededed;
  border-radius: 10px;
}

.product_discussion_menu_right{
  font-size: 18px;
  font-weight: 400;
  color: var(--main-color);
  margin-top: 20px;
  margin-bottom: 20px;
  width: 303px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.product_discussion_menu_right span{
  cursor: pointer;
  display: flex;
  align-items: center;
}

.product_discussion_menu_right span::after {
  content: "";
  display: block;
  height: 18px;
  width: 2px;
  background-color: var(--main-color);
  border-radius: 0;
  margin: 0 6px;
}

.product_discussion_menu_right span:nth-last-child(1)::after {
  display: none;
}

.product_discussion_menu_left {
  display: flex;
  justify-content: left;
  margin: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #363636;
  /*width: 1156px;*/
  white-space: nowrap;
}

.product_discussion_menu_left div {
  margin-right: 20px;
  padding: 10px;
  cursor: pointer;
}

.product_discussion_menu_left .select {
  color: var(--main-color);
  border-bottom: 6px solid var(--main-color);
}

.product_discussion_content {
  background-color: #FFFFFF;
  margin-left: 36px;
  padding: 5px;
  margin-bottom: 20px;
}

.product_discussion_content_status {
  color: #FFFFFF;
  font-size: 14px;
  width: 120px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.resolved {
  background-color: var(--main-color);
}

.default_color {
  color: #848484;
  background-color: #e8e8e8;
}

.unresolved {
  background-color: #FFA780;
}

.experience {
  background-color: #C8DBE2;
}

.product_discussion_content_item {
  display: flex;
  border: 1px solid #E8E8E8;
  cursor: pointer;
}

.content_item_left {
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #E8E8E8;
  margin: 10px 0;
}

.label{
  display: flex;
  align-items: center;
  color: #ffffff;
}

.label div:nth-child(1) {
  display: flex;
  background-color: #92B8C5;
  color: #FFFFFF;
  font-size: 12px;
  min-width: 60px;
  height: 20px;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0 2px;
}

.label div:nth-child(2) {
  border: 11px solid transparent;
  border-left: 20px solid #92B8C5;
  width: 0;
  height: 0;
}

.content_item_title {
  margin: 20px 10px;
  display: flex;
}

.content_item_bottom {
  color: #AEAEAE;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.content_item_bottom img {
  width: 23px;
  height: 23px;
  margin-right: 10px;
  border-radius: 50%;
}

.content_item_bottom span:first-child {
  color: var(--main-color);
}

.content_item_bottom span {
  display: flex;
  align-items: center;
  margin: 5px 10px;
}

.content_item_bottom i {
  margin-right: 5px;
}

.questions2 {
  margin: 14px 20px 20px 20px;
  background-color: #ffffff;
}

.questions2_title {
  color: #848484;
  font-size: 18px;
  font-weight: 400;
  background-color: #F1F1F1;
  text-align: left;
  line-height: 47px;
  padding-left: 10px;
  margin: 10px 30px;
}

.questions2_title input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: none;
  font-size: 18px;
  color: #303133;
}

.questions2_head {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  line-height: 48px;
}

.questions2_head i {
  color: #AEAEAE;
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.questions2_describe {
  font-size: 12px;
  font-weight: 400;
  color: #AEAEAE;
  line-height: 20px;
  display: flex;
  margin: 10px 0 10px 70px;

}

.questions2_describe span:first-child::after,.questions3_describe_span::after {
  content: "";
  display: inline-block;
  height: 12px;
  width: 2px;
  background-color: #AEAEAE;
  border-radius: 0;
  margin: 0 6px;
}

.questions2_describe img{
  width: 16px;
  height: 16px;
}

.questions2_describe span:last-child {
  margin-left: 380px;
  color: #3E89D6;
}

.questions2_label {
  color: #848484;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  margin: 30px 30px;
}

.questions2_label_content {
  color: #848484;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  padding-left: 10px;
  /*width: 247px;*/
  height: 29px;
  display: flex;
}

.questions2_label_content input {
  background-color: #F1F1F1;
  border: none;
  outline: none;
  font-size: 18px;
  color: #303133;
}

.questions2_label_content i {
  background-color: #F1F1F1;
  height: 29px;
  width: 29px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.questions2_label_content div:nth-child(2) {
  cursor: pointer;
}

.questions2_label_content span{
  background-color: #f1f1f1;
  margin: 0 5px;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.questions2_label_content i {
  cursor: pointer;
}

.questions2_textarea {
  margin: 20px;
  border-radius: 5px;
  outline: none;
  padding: 10px;
  font-size: 18px;
  /*background-color: #F1F1F1;*/
  border: 1px solid #F1F1F1;
  min-height: 300px;
}

.questions2_textarea textarea {
  resize: none;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: none;
  font-size: 18px;
  color: #303133;
}

.published {
  width: 283px;
  height: 63px;
  background-color: var(--main-color);
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  line-height: 63px;
  margin: 20px auto;
  cursor: pointer;
}

.questions3 {
  margin: 14px 20px 20px 20px;
  background-color: #ffffff;
}

.questions3_top_illustrate {
  color: #AEAEAE;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin: 10px 80px;
  justify-content: center;
}

.questions3_top_illustrate img {
  width: 23px;
  height: 23px;
  margin-right: 10px;
}

.questions3_top_illustrate span {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.questions3_top_illustrate span:first-child {
  margin-left: 100px;
}

.questions3_top_illustrate .label {
  margin-left: 30px;
}

.questions3_top_illustrate i {
  margin-right: 5px;
}

.questions3_title {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  line-height: 55px;
  margin: 30px 40px;
}

.spacing {
  margin-left: 300px !important;
}

.questions3_discussion_content_item{
  display: flex;
  border: 1px solid #E8E8E8;
}

.questions3_content_item_left{
  width: 180px;
  border-right: 1px solid #E8E8E8;
  margin: 10px 0;
}

.questions3_discussion_content_status{
  color: #FFFFFF;
  font-size: 12px;
  width: 120px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.questions3_content_item_left img {
  width: 23px;
  height: 23px;
  margin-right: 10px;
}

.questions3_content_item_left span {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.questions3_content_item_left span:first-child {
  color: var(--main-color);
}

.questions3_content_item_title {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 36px;
  margin: 20px;
}

.landlord {
  font-size: 12px;
  font-weight: 300;
  color: #AEAEAE;
  line-height: 36px;
  text-align: center;
}

.questions3_bottom_illustrate {
  display: flex;
  justify-content: flex-end;
  margin: 10px 80px;
}

.questions3_bottom_illustrate span{
  color: #AEAEAE;
  margin: 0 10px;
  font-size: 14px;
  line-height: 14px;
}

.questions3_bottom_illustrate span:nth-child(2),.questions3_bottom_illustrate span:nth-child(7),.questions3_bottom_illustrate span:nth-child(8){
  color: #3E89D6;
  border-bottom: 1px solid #3E89D6;
  cursor: pointer;
}

.questions3_head {
  font-size: 24px;
  font-weight: 400;
  color: #000000;
  line-height: 55px;
  margin: 0 40px;
}

.questions3_head i {
  color: #AEAEAE;
  width: 24px;
  height: 24px;
}

.padding_none {
  padding: 0 !important;
}

.answer_number {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  margin: 20px 20px;
  border-bottom: 1px solid #E8E8E8;
}

.answer_list {
  margin-left: 40px;
  border-bottom: 1px solid #E8E8E8;
}

.answer_list_content {
  font-size: 16px;
  margin-top: 10px;
}

.answer_list_operate {
  display: flex;
  margin: 10px 0;
  color: #999999;
  font-size: 16px;
}

.answer_list_operate div:nth-child(3) {
  margin: 0 10px;
  cursor: pointer;
  color: #3E89D6;
  border-bottom: 1px solid #3E89D6;
}

.comment_number {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  margin: 20px 20px;
  border-bottom: 1px solid #E8E8E8;
}

.comment_name {
  color: #999999;
  font-size: 16px;
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.comment_name span {

}

.comment_name .nick_name {
  color: #d55555;
  margin-right: 10px;
}

.comment_content {
  font-size: 16px;
  margin-left: 20px;
}

.comment_operate {
  display: flex;
  margin: 10px 20px;
  color: #3E89D6;
  font-size: 16px;
  cursor: pointer;
}

.reply {
  background-color: #fafafa;
  margin-left: 40px;
  padding: 5px;
}

.reply_name {
  color: #999999;
  font-size: 16px;
}

.reply_name span {
  color: #d55555;
}

.reply_content {
  font-size: 16px;
  margin: 10px 0;
}

.reply_operate {
  display: flex;
  margin: 10px 0;
  color: #3E89D6;
  font-size: 16px;
  cursor: pointer;
}

.imageHeader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 5px;
}

.answer_list_name {
  display: flex;
  justify-content: center;
}

.answer_list_name span {
  margin-right: 10px;
}

.content_item_title_text {
  margin-right: 10px;
  text-overflow :ellipsis;
  white-space :nowrap;
  overflow : hidden;
  font-size: 18px;
}

.no_data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.day-counter {
  position: absolute;
  top: 185px;
  left: 905px;
  transform: translate(-50%, -50%);
  /*background: rgba(255, 255, 255, 0.9);*/
  padding: 10px 15px;
  border-radius: 4px;
  text-align: center;
}

.day-number {
  font-size: 30px;
  font-weight: bold;
  color: #394556;
  font-family: Microsoft YaHei;
}
</style>
